body {
  background-color: #282c34;
  color: white;
}

.bandplan {
  flex: 1;
}

.frequency-block {
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.frequency-block:hover {
  overflow: visible;
  z-index: 1000;
  background-color: white;
}

span.frequency {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
}

span.comment {
  display: block;
  font-size: 0.8rem;
  padding: 0.5rem;
}

.frequency-block:hover span {
  background-color: black;
  transform: scale(1.1) translate(-5rem, 0.5rem);
  z-index: 1000;
  box-shadow: 0.2rem 0.2rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  border-top-left-radius: 0.5rem;
  padding: 0.5rem 1rem;
  z-index: 1000;
  background-color: white;
  color: black;
}
